import request from './request'

const api = {
	// 获取列表
	lists: '/classintegralrule/lists',
	// 添加
	add: '/classintegralrule/add',
	// 删除
	dele: '/classintegralrule/del',
	// 编辑
	edit: '/classintegralrule/update',
	// 详情
	info: '/classintegralrule/read',
}

export {
	request,
	api
}
